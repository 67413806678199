import React from 'react'

const ErrorPage = () => {
  return (
    <>
    <div className='d-flex justify-content-center align-items-center' style={{height:"100vh"}}>
    <h1 className="text-center text-danger display-3">Page Not Found</h1>
    </div>
    </>
  )
}

export default ErrorPage