import React from 'react'

const Footer = () => {
    return (
        <>
            <div>
                {/* <footer className="main-footer mt-3 text-dark" id="footer">
                    <strong>Copyright © 2024 <a href="https://adminlte.io">PMS</a>.&nbsp;</strong>
                     All rights reserved.
                  
                </footer>
               
                <aside className="control-sidebar control-sidebar-dark">
                  
                </aside> */}
                
            </div>

        </>
    )
}

export default Footer